import { type FC } from 'react';
import { type Environment, fetchQuery, graphql } from 'react-relay';

import serverVars from 'server-vars';

import { browser } from 'dibs-client-check';
import { mobile } from 'dibs-client-check';
import { getBuyerId } from 'dibs-cookie-jar';
import { PAGE_TYPE, BROWSE_TYPES } from 'dibs-constants/exports/pageTypes';
import { VisitCountModel } from 'dibs-visit-tracking/exports/visitCountModel';

import { isAppDownloadOnCooldown } from '../../authModal/shared/AppDownloadModal/helpers';
import { importModuleAndRenderNotification } from './helpers/renderHelpers';
import { gaLabels } from '../../authModal/postAuth/constants';

import { type appDownloadModalConfigQuery } from './__generated__/appDownloadModalConfigQuery.graphql';

const APP_DOWNLOAD_DISPLAY_LIMIT = 5;
const APP_DOWNLOAD_DISPLAY_LIMIT_MOBILE = 2;

const appDownloadModal = async (): Promise<FC<{ launchOrigin?: string }>> => {
    return (
        await import(
            /* webpackChunkName: "AppDownloadModal" */
            '../../authModal/shared/AppDownloadModal/AppDownloadModal'
        )
    ).AppDownloadModal;
};

export const appDownloadModalAction = () => async (): Promise<void> => {
    importModuleAndRenderNotification({
        importFunc: appDownloadModal,
        props: {
            launchOrigin: gaLabels.PAGEVIEW_LIMIT_REACHED,
        },
    });
};

export const appDownloadModalRule = async ({
    relayEnvironment,
}: {
    relayEnvironment: Environment;
}): Promise<boolean> => {
    const visitCountModel = new VisitCountModel();
    const sessionVisitCount = visitCountModel.get('session');
    const isMobile = serverVars.get('settings.isMobile');
    const isIphone = mobile.iPhone();
    const isLoggedIn = !!getBuyerId(document.cookie);

    const data = await fetchQuery<appDownloadModalConfigQuery>(
        relayEnvironment,
        graphql`
            query appDownloadModalConfigQuery($countryCode: String = "") {
                viewer {
                    appDownloadDisplayInfo {
                        isAppDownloadApplicable(countryCode: $countryCode)
                    }
                }
            }
        `,
        {}
    ).toPromise();

    const { viewer } = data || {};

    if (!viewer?.appDownloadDisplayInfo?.isAppDownloadApplicable) {
        return false;
    }

    if (isAppDownloadOnCooldown()) {
        return false;
    }

    if (isMobile && !isIphone) {
        return false;
    }

    if (!isLoggedIn && isIphone) {
        if (browser.safari() && sessionVisitCount < APP_DOWNLOAD_DISPLAY_LIMIT_MOBILE) {
            return false;
        }
        if (!browser.safari()) {
            return false;
        }
    }

    if (isLoggedIn && isMobile && sessionVisitCount < APP_DOWNLOAD_DISPLAY_LIMIT_MOBILE) {
        return false;
    }

    if (!isMobile && (!isLoggedIn || sessionVisitCount < APP_DOWNLOAD_DISPLAY_LIMIT)) {
        return false;
    }

    return true;
};

export default {
    name: 'appDownloadModalPrompt',
    rule: appDownloadModalRule,
    action: appDownloadModalAction,
    allowedPageTypes: [
        PAGE_TYPE.HOME,
        PAGE_TYPE.CREATOR,
        PAGE_TYPE.DEALER,
        PAGE_TYPE.PDP,
        PAGE_TYPE.SEARCH,
        ...BROWSE_TYPES,
    ],
    priority: 360,
};
