import SV from 'server-vars';
import { getBuyerId } from 'dibs-cookie-jar';
import { isPlaReferrer } from './referrerHelper';

export const canVisitorBePromptedLogin = (): boolean => {
    const isPdpPrintPage = SV.get('settings.isPrintPDP');
    const searchParams = new URLSearchParams(location.search);
    const loggedIn = getBuyerId(document.cookie);
    if (loggedIn || searchParams.has('modal') || isPdpPrintPage || isPlaReferrer()) {
        return false;
    }
    return true;
};
