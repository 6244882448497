import { type Dispatch } from 'redux';
import { type Environment } from 'react-relay/legacy';
import serverVars from 'server-vars';
import { ClientCookieManager } from 'dibs-consented-storage';

import { browser } from 'dibs-client-check';
import { addLoginHandlers } from 'dibs-buyer-layout/exports/loginHandlers';
import { PAGE_TYPE, BROWSE_TYPES } from 'dibs-constants/exports/pageTypes';
import {
    SOCIAL_CASE_GOOGLE_ONE_TAP_LOGIN,
    GOOGLE_ONE_TAP_FLOW,
} from '../../authModal/authentication/authFlowConstants';
import { GOOGLE } from '../../authModal/authentication/socialLogin/socialConstants';
import widgetLoader from 'dibs-widget-loader';

import { canVisitorBePromptedLogin } from './helpers/canVisitorBePromptedLogin';
import { wasUserRedirectedByEmailLoginLink } from './helpers/wasUserRedirectedByEmailLoginLink';
import getRelayEnvironment from '../../relayClientEnvironment';
import { saveUserAddress } from '../../helpers/saveUserAddress';
import { GOOGLE_ONE_TAP_COOKIE_TIMEOUT_KEY } from '../../authModal/authentication/socialLogin/googleLoginHandlers';

type GoogleLoginHandlersType =
    typeof import('../../authModal/authentication/socialLogin/googleLoginHandlers');
type HandleSocialLoginType = typeof import('../../authModal/authentication/handleSocialLogin');
type AuthActionsType = typeof import('../../authModal/authentication/authActions');

const loadAuthModalModules = (): Promise<
    [GoogleLoginHandlersType, HandleSocialLoginType, AuthActionsType]
> =>
    Promise.all([
        import(
            /* webpackChunkName: "googleLoginHandlers" */
            '../../authModal/authentication/socialLogin/googleLoginHandlers'
        ),
        import(
            /* webpackChunkName: "handleSocialLogin" */
            '../../authModal/authentication/handleSocialLogin'
        ),
        import(
            /* webpackChunkName: "authActions" */
            '../../authModal/authentication/authActions'
        ),
    ]);

export const promptGoogleOneTap =
    (options?: { environment: Environment; runNextNotification: () => void }) =>
    async (dispatch: Dispatch): Promise<void> => {
        const [{ loginOneTapGoogle }, { handleSocialLogin }, { setAuthResponse }] =
            await loadAuthModalModules(); // Only required because of app-buyer-finding/src/actions/sharedUserStorageActions.js

        const environment = options?.environment || getRelayEnvironment();
        const ga = { label: 'google one tap' };

        await widgetLoader.load('google', { relayEnvironment: environment });

        // Close Google One Tap prompt if user logs-in via other auth flows.
        addLoginHandlers(() => {
            window.google?.accounts?.id?.cancel?.();
        });

        await loginOneTapGoogle({
            onSuccess: payload => {
                dispatch(setAuthResponse(payload));
                // @ts-ignore TS doesn't recognize userId under Pick type
                saveUserAddress({ ...payload.userInfo, environment });
                handleSocialLogin({
                    isGdprApplicable: payload.isGdprApplicable || false,
                    isAppDownloadApplicable: payload.isAppDownloadApplicable || false,
                    flow: GOOGLE_ONE_TAP_FLOW,
                    // @ts-ignore TS doesn't recognize userId under Pick type
                    payload: {
                        ...payload.userInfo,
                        socialAuthSuccessType:
                            payload.socialAuthSuccessType || SOCIAL_CASE_GOOGLE_ONE_TAP_LOGIN,
                        socialAuthName: GOOGLE,
                        environment,
                    },
                    launchOrigin: ga.label,
                });
            },
            onError: () => {},
            onDismissedMoment: () => {},
            onSkippedMoment: () => {
                options?.runNextNotification();
            },
            environment,
            ga,
            name: 'google',
            marketingOptIn: false,
        });
    };

export const shouldPromptGoogleOneTap = (): boolean => {
    const isGoogleOneTapOnDelay = ClientCookieManager.get(GOOGLE_ONE_TAP_COOKIE_TIMEOUT_KEY);
    const wasRedirectedByEmailLoginLink = wasUserRedirectedByEmailLoginLink();
    const isMobile = serverVars.get('settings.isMobile');
    const isMobileFirefoxSafari = isMobile && (browser.mozilla() || browser.safari());

    if (isGoogleOneTapOnDelay) {
        return false;
    }

    if (isMobileFirefoxSafari) {
        return false;
    }

    if (!canVisitorBePromptedLogin()) {
        return false;
    }

    if (wasRedirectedByEmailLoginLink) {
        return false;
    }

    return true;
};

export default {
    name: 'googleOneTapPrompt',
    rule: shouldPromptGoogleOneTap,
    action: promptGoogleOneTap,
    allowedPageTypes: [
        PAGE_TYPE.HOME,
        PAGE_TYPE.CREATOR,
        PAGE_TYPE.DEALER,
        PAGE_TYPE.PDP,
        PAGE_TYPE.SEARCH,
        ...BROWSE_TYPES,
    ],
    priority: 240,
};
