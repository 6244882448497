import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'dibs-react-intl';

import serverVars from 'server-vars';

export function makeContainerElement(containerClassName) {
    const wrapperElement = document.createElement('div');

    if (containerClassName) {
        wrapperElement.classList.add(containerClassName);
    }

    document.body.appendChild(wrapperElement);

    return wrapperElement;
}

/**
 * This is a helper for creating global notifications. It wraps a component in intl provider and creates a container
 * element to render it into (unless there's an existing element already).
 *
 * @param {Object} options
 * @param {*} options.Component - react component
 * @param {Object=} options.props - {optional} props for component
 * @param {HTMLElement=} options.existingContainerElement - {optional} if this is passed, use this instead of creating a new container element
 * @param {string=} options.containerClassName - {optional} adds class name to container element (if new one is created)
 */
export function renderNotification(options) {
    const { Component, props = {}, existingContainerElement, containerClassName } = options;

    const locale = serverVars.get('locale');
    const messages = serverVars.get('messages');

    const element = (
        <IntlProvider locale={locale} messages={messages}>
            <Component {...props} />
        </IntlProvider>
    );

    const containerElement = existingContainerElement || makeContainerElement(containerClassName);
    const root = createRoot(containerElement);
    root.render(element);
}

/**
 * This is a helper for creating global notifications with dynamic imports. It takes an import function that returns a
 * promise that resolves to a component and calls renderNotification with that component.
 *
 * @param {Object} options
 * @param {Function} options.importFunc - function that dynamically imports a module and returns a promise that resolves to a react component
 * @param {Object=} options.props - {optional} props for component
 * @param {HTMLElement=} options.existingContainerElement - {optional} if this is passed, use this instead of creating a new container element
 * @param {string=} options.containerClassName - {optional} adds class name to container element (if new one is created)
 */
export function importModuleAndRenderNotification(options) {
    const { importFunc, props = {}, existingContainerElement, containerClassName } = options;

    importFunc().then(Component =>
        renderNotification({ Component, props, existingContainerElement, containerClassName })
    );
}
