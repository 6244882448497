import { getBuyerId, getEverLoggedIn } from 'dibs-cookie-jar';
import { localStorage } from 'dibs-browser-storage';
import authModalLoader from 'dibs-buyer-layout/src/utils/AuthModalLoader';
import { steps, gaLabels } from 'dibs-buyer-layout/src/authModal/postAuth/constants';
import {
    shouldShowTradeLogin,
    initTradeLogin,
} from 'dibs-buyer-layout/exports/isLoggedOutTradeHelper';
import {
    shouldPromptGoogleOneTap,
    promptGoogleOneTap,
} from 'dibs-buyer-layout/exports/googleOneTapPrompt';
import {
    appDownloadModalAction,
    appDownloadModalRule,
} from 'dibs-buyer-layout/exports/appDownloadModalPrompt';

import { VisitCountModel } from 'dibs-visit-tracking/exports/visitCountModel';
import {
    KEY_SINCE_REG_PROMPT,
    VISITS_PER_SESSION_LOGIN_PROMPT,
} from 'dibs-visit-tracking/exports/visitCountConstants';

import { sharedLoginCheck } from '../authentication/sharedLoginCheck';
import { VISIT_COUNT, PSEUDO_SESSION } from 'dibs-buyer-layout/exports/userStorageConstants';
import { getDwelledOnPage } from 'dibs-buyer-layout/exports/dwelledOnPage';
import { trackAbTestV2Variant } from 'dibs-ab-tests/exports/clientAbTestV2';

// if 'session' from pseudoSession has reached the regPromptLimit, then
// display the auth modal. if user has logged in show login; otherwise, show registration.
// TODO: Remove once mobile specific pages are set up to use DBL notification queue
export function showAuthModal(relayEnvironment) {
    return async dispatch => {
        //https://1stdibs.atlassian.net/browse/TRADE-4598
        //if user meets loggedout trade criteria, show trade specific login modal
        if (shouldShowTradeLogin()) {
            initTradeLogin();
            return;
        }

        const userId = getBuyerId(document.cookie);
        const visitCount = localStorage.getItem(VISIT_COUNT) || {};
        const pseudoSession = localStorage.getItem(PSEUDO_SESSION) || {};
        const shouldPromptLogin = getEverLoggedIn(document.cookie);

        const { session = 0 } = pseudoSession;

        const { regPromptLimit = VISITS_PER_SESSION_LOGIN_PROMPT } = visitCount;
        const visitCountModel = new VisitCountModel();
        const didDwellOnPage = getDwelledOnPage();
        const wasModalPromptedThisSession = visitCountModel.get(KEY_SINCE_REG_PROMPT) < session;
        const didDwellOnPageOrReachPromptLimit = didDwellOnPage || session === regPromptLimit;

        if (!userId && !wasModalPromptedThisSession && didDwellOnPageOrReachPromptLimit) {
            visitCountModel.set(KEY_SINCE_REG_PROMPT, 0);
            visitCountModel.save();

            const flow = authModalLoader.constants.PAGEVIEW_LIMIT_FLOW;
            if (shouldPromptLogin) {
                sharedLoginCheck({
                    authOptions: {
                        flow,
                        ga: { label: gaLabels.LABEL_PAGEVIEW_LIMIT },
                    },
                });
            } else {
                trackAbTestV2Variant('mwRegistrationRedesign');
                authModalLoader.showPostAuth({
                    step: steps.USER_TYPE_SELECTION,
                    authFlow: flow,
                    postAuthFlow: authModalLoader.constants.AUTO_PROMPT_REGISTER,
                    launchOrigin:
                        session !== regPromptLimit
                            ? gaLabels.LABEL_DWELL_TIME_REACHED
                            : gaLabels.LABEL_PAGEVIEW_LIMIT,
                });
            }
            return;
        }

        if (shouldPromptGoogleOneTap()) {
            promptGoogleOneTap()(dispatch);
            return;
        }

        if (relayEnvironment && (await appDownloadModalRule({ relayEnvironment }))) {
            appDownloadModalAction()(dispatch);
            return;
        }
    };
}
